.contact_bar_wrapper {
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
}

.contact_bar {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    position: relative;
}

.vertical_space {
    width: 1px;
    height: 120px;
    margin-top: 20px;
}

.icon {
    margin-top: 15px;
    cursor: pointer;
    transition: var(--transition);
    color: var(--lightest-slate);
}

.icon:hover {
    color: var(--blue);
    transform: translateY(-3px);
}