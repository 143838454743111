.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: calc(100vh - 800px); */
    /* height: 12vh; */
}

.left {
    flex: 1;
}

a {
    color: var(--blue);
}
.desc {
    font-family: var(--font-sans);
    color: var(--slate);
    font-size: var(--fz-xl);
}

/* .skill_point {
    color: var(--blue);
    display: flex;
    flex-wrap: wrap;
} */

/* .skill_point {
    color: var(--blue);
    display: flex;
    flex-wrap: wrap;
    align-content:stretch;
} */

ul {
    -moz-column-count: 4;
    -moz-column-gap: 10px;
    -webkit-column-count: 4;
    -webkit-column-gap: 10px;
    column-count: 4;
    column-gap: 10px;
}

li {
    list-style: none;
}

li:not(:last-child) {
    margin-bottom: 15px;
}

li:before{
    content: "\25B9";
    color: var(--blue);
    letter-spacing: 8px;
}

/* 
.right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
} */

/* .profile_img {
    width: 300px;
    border-radius: 10px;
}

.img_border {
    width: 300px;
    height: 300px;
    position: absolute;
    border: 2px solid var(--green);
    border-radius: 10px;
    margin-top: 30px;
    margin-left: 30px;
    transition: var(--transition);
    z-index: -1;
}

.profile_img:hover ~ .img_border {
    margin-top: 15px;
    margin-left: 15px;
} */