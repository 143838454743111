.card_container {
    width: 300px;
    overflow: hidden;
    box-shadow: 0px 0px 15px -5px;
    transition: 0.5s;
    animation: ease-in-out;
}
  

.card_container:hover {
    transform: scale(1.08);
    box-shadow: 0px 0px 15px 0px;
}

/* .card_thumbnail img{
    overflow: hidden;
    height: 200px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    display: block;
} */

.card_thumbnail video {
    overflow: hidden;
    height: 200px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    display: block;
    padding-top: 15px;
}
.card_content {
    margin: 1rem;
    padding: 0.5rem;
    text-align: center;
}

h2, p {
    margin: 0;
    
}

h2 {
    padding-bottom: 0.8rem;
}

.card_title {
    margin-bottom: 0.5rem;
    color: var(--lightest-slate);
}

.card_body {
    word-spacing: 3.5px;
    text-align: left;
}
.card_links {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
}

.card_skills {
    display: flex;
    justify-content: space-around;
    color: var(--blue);
    font-family: var(--font-mono);
}

.icon {
    margin-top: 15px;
    cursor: pointer;
    transition: var(--transition);
    color: var(--lightest-slate);
}

.icon:hover {
    color: var(--blue);
    transform: translateY(-3px);
}