.container{
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
    max-width: 700px;
    padding-top: 50px;
}

.index {
    font-size: var(--fz-xxl);
    font-weight: 400;
    color: var(--blue);
}

.heading {
    font-family: var(--font-sans);
    font-size: var(--fz-heading);
    color: var(--lightest-slate);
    font-weight: 600;
    margin-left: 15px;
}

.line {
    flex-grow: 1;
    height: 1px;
    background: var(--lightest-navy);
    align-self: center;
    margin-left: 15px;
}