body {
  margin: 0;
  font-family: var(--font-mono);
  background-color: var(--navy);
  color: var(--light-slate);
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}
:root {
  --font-sans: "Calibre","Inter","San Francisco","SF Pro Text",-apple-system,system-ui,sans-serif;
  --font-mono: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;
  --transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
  --dark-navy: #020c1b;
  --navy: #111111;
  --light-navy: #112240;
  --lightest-navy: #233554;
  --transparent-navy: #111111d9;
  --navy-shadow: rgba(2,12,27,.7);
  --dark-slate: #495670;
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --blue: #64ceff;
  --blue-tint: rgba(100,255,218,.1);
  --fz-xxs: 12px;
  --fz-xs: 13px;
  --fz-sm: 14px;
  --fz-md: 14px;
  --fz-lg: 18px;
  --fz-xl: 20px;
  --fz-xxl: 22px;
  --fz-heading: 32px;
}
