.nav_container {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 70px;
    background-color: var(--transparent-navy);
    box-shadow: var(--navy-shadow);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}

.nav_left {
    padding-left: 50px;
}

.nav_right {
    padding-right: 50px;
}

.nav_left > a {
    color: var(--blue);
    font-size: var(--fz-xxl);
    font-weight: bold;
    font-family: var(--font-sans);
}

.nav_link {
    font-size: var(--fz-sm);
    font-family: var(--font-sans);
    color: var(--lightest-slate);
    cursor: pointer;
    margin-left: 26px;
}

.nav_link > span {
    color: var(--blue);
    margin-right: 5px;
}

.nav_link:hover {
    color: var(--blue);
}

.nav_resume_btn {
    outline: none;
    font-size: var(--fz-xs);
    background: transparent;
    border: 1px solid var(--blue);
    color: var(--blue);
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    margin-left: 26px;
}

.nav_resume_btn:hover {
    background: var(--blue-tint);
}